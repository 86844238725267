import React from "react";

const SixPoints = ({ number }) => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.8586 4.15054C39.6951 -1.93443 48.8598 0.521245 48.2738 7.20926L47.5566 15.3956C47.2309 19.1125 50.3405 22.2221 54.0573 21.8964L62.2437 21.1792C68.9318 20.5932 71.3874 29.7579 65.3024 32.5944L57.8542 36.0665C54.4725 37.6429 53.3343 41.8907 55.4748 44.9467L60.1891 51.6777C64.0406 57.1767 57.3316 63.8857 51.8325 60.0343L45.1015 55.3199C42.0455 53.1795 37.7977 54.3177 36.2213 57.6994L32.7493 65.1476C29.9127 71.2326 20.7481 68.7769 21.334 62.0889L22.0512 53.9025C22.3769 50.1857 19.2673 47.0761 15.5505 47.4018L7.36407 48.119C0.676057 48.7049 -1.77959 39.5403 4.30538 36.7037L11.7536 33.2317C15.1353 31.6553 16.2735 27.4075 14.133 24.3514L9.41871 17.6204C5.56726 12.1214 12.2763 5.41244 17.7753 9.2639L24.5063 13.9782C27.5623 16.1187 31.8101 14.9805 33.3865 11.5988L36.8586 4.15054Z"
        fill="#FF0000"
      />

      <text
        x="52%"
        y="52%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20"
        fontWeight="600"
        fill="white"
      >
        {number}
      </text>
    </svg>
  );
};

export default SixPoints;
