import React, { useState, useEffect } from "react";
import styles from "./Sketches.module.css";

function Sketches() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.procreateContentContainer}>
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm+(1)+(1).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm+(1).webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/Pasted+Graphic+31.png"
        alt="Pen and marker drawings of hands"
        className={styles.procreateVisual}
      />
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm+(2).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm+(2).webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/Pasted+Graphic+32.png"
        alt="Pen still life drawing"
        className={styles.procreateVisual}
      />
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/779933.JPEG"
        alt="Pencil study from a museum"
        className={styles.procreateVisual}
      />
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm+(3).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.procreateVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Sketches/ezgif.com-gif-to-webm+(3).webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default Sketches;
