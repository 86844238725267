import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { projects, sketches, development } from "./Projects.constants";
import { selectedProjectState } from "../../atoms/SelectedProject.atom";
import styles from "./Projects.module.css";
import ArrowRight from "../../icons/arrowRight";
import ArrowDown from "../../icons/arrowDown";

function Projects() {
  const [selectedProject, setSelectedProject] =
    useRecoilState(selectedProjectState);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleProject = (project) => {
    if (selectedProject === project && screenWidth <= 1024) {
      setSelectedProject(null);
    } else {
      setSelectedProject(project);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDesktop = screenWidth >= 1024;

  return (
    <div
      className={styles.projectsPageWrapper}
      style={
        isDesktop
          ? {
              borderTop: `3px solid ${selectedProject?.color_code}`,
            }
          : {}
      }
    >
      <div className={styles.projectsPageListContainer}>
        <div className={styles.subheading}>Development:</div>
        {development.map((project) => (
          <div className={styles.projectsPageList}>
            <div
              key={project.key}
              onClick={() => toggleProject(project)}
              className={styles.projectsPageItem}
              style={
                !isDesktop && selectedProject === project
                  ? {
                      borderTop: `3px solid ${selectedProject?.color_code}`,
                    }
                  : {}
              }
            >
              <div className={styles.projectsArrow}>
                {selectedProject === project ? (
                  <ArrowDown color={project.color_code} />
                ) : (
                  <ArrowRight />
                )}
              </div>
              <div
                className={styles.projectsPageItemTitle}
                style={{
                  color: `${
                    selectedProject === project
                      ? selectedProject?.color_code
                      : "#fff"
                  }`,
                }}
              >
                {project.title}
              </div>
              <div
                className={styles.projectsPageItemDate}
                style={{
                  color: `${
                    selectedProject === project
                      ? selectedProject?.color_code
                      : "#fff"
                  }`,
                }}
                x
              >
                {project.year}
              </div>
            </div>
            <div className={styles.selectedProjectContentList}>
              {selectedProject === project && project?.content}
            </div>
          </div>
        ))}
        <div className={styles.subheading}>Design Engineering Projects:</div>
        {projects.map((project) => (
          <div className={styles.projectsPageList}>
            <div
              key={project.key}
              onClick={() => toggleProject(project)}
              className={styles.projectsPageItem}
              style={
                !isDesktop && selectedProject === project
                  ? {
                      borderTop: `3px solid ${selectedProject?.color_code}`,
                    }
                  : {}
              }
            >
              <div className={styles.projectsArrow}>
                {selectedProject === project ? (
                  <ArrowDown color={project.color_code} />
                ) : (
                  <ArrowRight />
                )}
              </div>
              <div
                className={styles.projectsPageItemTitle}
                style={{
                  color: `${
                    selectedProject === project
                      ? selectedProject?.color_code
                      : "#fff"
                  }`,
                }}
              >
                {project.title}
              </div>
              <div
                className={styles.projectsPageItemDate}
                style={{
                  color: `${
                    selectedProject === project
                      ? selectedProject?.color_code
                      : "#fff"
                  }`,
                }}
                x
              >
                {project.year}
              </div>
            </div>
            <div className={styles.selectedProjectContentList}>
              {selectedProject === project && project?.content}
            </div>
          </div>
        ))}
        <div className={styles.subheadingContainer}>
          <div className={styles.subheading}>Sketches:</div>
        </div>
        {sketches.map((project) => (
          <div className={styles.projectsPageList}>
            <div
              key={project.key}
              onClick={() => toggleProject(project)}
              className={styles.projectsPageItem}
              style={
                !isDesktop && selectedProject === project
                  ? {
                      borderTop: `3px solid ${selectedProject?.color_code}`,
                    }
                  : {}
              }
            >
              <div className={styles.projectsArrow}>
                {selectedProject === project ? (
                  <ArrowDown color={project.color_code} />
                ) : (
                  <ArrowRight />
                )}
              </div>
              <div
                className={styles.projectsPageItemTitle}
                style={{
                  color: `${
                    selectedProject === project
                      ? selectedProject?.color_code
                      : "#fff"
                  }`,
                }}
              >
                {project.title}
              </div>
              <div
                className={styles.projectsPageItemDate}
                style={{
                  color: `${
                    selectedProject === project
                      ? selectedProject?.color_code
                      : "#fff"
                  }`,
                }}
              >
                {project.year}
              </div>
            </div>
            <div className={styles.selectedProjectContentList}>
              {selectedProject === project && project?.content}
            </div>
          </div>
        ))}
      </div>
      {isDesktop && (
        <div
          className={`${styles.verticalLine} ${
            styles[selectedProject?.theme_color + "Border"]
          }`}
        ></div>
      )}
      {isDesktop && (
        <div
          className={`${styles.selectedProjectContentBox} ${
            styles[selectedProject?.theme_color + "Border"]
          }`}
        >
          {selectedProject?.content}
        </div>
      )}
    </div>
  );
}

export default Projects;
