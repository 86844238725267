import React from "react";
import styles from "./Procreate.module.css";

function Procreate() {
  return (
    <div className={styles.procreateContentContainer}>
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Procreate/coffee.webp"
        alt="Illustration of coffee grinder"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Procreate/bus-poster.webp"
        alt="Illustration of buses"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Procreate/busses.webp"
        alt="Illustration of buses"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Procreate/spray-bottle.webp"
        alt="Illustration of spray bottle"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Procreate/red-house.webp"
        alt="Illustration of martian growhouse"
        className={styles.procreateVisual}
      />
    </div>
  );
}
export default Procreate;
