import React, { useState, useEffect } from "react";
import styles from "./Turntable.module.css";

function Turntable() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.turntableContentContainer}>
      I spent a summer working with the Audio Experience Design Laboratory -
      Imperial College London. It is a research team based within the Dyson
      School of Design Engineering. The research team investigates a range of
      areas concerning audio experience, including both physical and perceptual
      aspects. As a design engineer I was able to apply my engineering and
      product design skills to help them with engineering aspects of their
      research projects.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/table.webp"
        }
        alt="Turntable"
        className={styles.turntableVisual}
      />
      My primary responsibility was building a piece of equipment for their
      audio experiments. This equipment was a rotary table, used in experiments
      for measuring how sound is distorted as it reaches a persons ears. I was
      responsible for designing and manufacturing a precise and heavy-duty
      rotary table that a rotated a human with 1.5° accuracy. I designed the
      housing structure, drive chain, electronics and control system.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.turntableVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/spinning.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.turntableVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/spinning.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/turntable.webp"
        }
        alt="Experiment set-up"
        className={styles.turntableVisual}
      />
      Turntable in situ, for use in HRTF audio experiments in an anechoic
      environment.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/drive.webp"
        }
        alt="Drive train"
        className={styles.turntableVisual}
      />
      The structure of the casing and the drive train was designed in the CAD
      software OnShape. All components were either stock components or custom
      made.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.turntableVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/gear.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.turntableVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/gear.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/circuit-diagram.webp"
        }
        alt="Electronics diagram"
        className={styles.turntableVisual}
      />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/electronics.webp"
        }
        alt="Close up of electronics"
        className={styles.turntableVisual}
      />
      The electronics are controlled from an arduino, with control instructions
      sent via MATLAB.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/casing-detail.webp"
        }
        alt="Close up of casing"
        className={styles.turntableVisual}
      />
      Laser-cut casing was used to house the electronics.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/milling.webp"
        }
        alt="Close up of milling process"
        className={styles.turntableVisual}
      />
      Workshop skills including metalwork and woodwork were applied in order to
      build the custom components.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/laser.webp"
        }
        alt="Turntable in situ"
        className={styles.turntableVisual}
      />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/laser-detail.webp"
        }
        alt="Turntable in situ"
        className={styles.turntableVisual}
      />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/line-drawing.png"
        }
        alt="Exploded view of final product"
        className={styles.turntable}
      />
    </div>
  );
}

export default Turntable;
