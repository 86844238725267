import React from "react";
import styles from "./PhraseComponent.module.css";

const PhraseComponent = ({ phrase, category, color, selectedCategory }) => {
  const phraseStyle = {
    "--phrase-color": color,
  };

  const deselected =
    selectedCategory !== "all" && category !== selectedCategory;

  return (
    <span
      className={`${styles.phrase} ${deselected ? styles.deselected : ""}`}
      style={phraseStyle}
    >
      {phrase}
    </span>
  );
};

export default PhraseComponent;
