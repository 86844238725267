import React from "react";

const ArrowDown = ({ color }) => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5688 14.0088L12 24.5688M12 24.5688L1.43123 14.0088M12 24.5688L12 0.568786"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowDown;
