import React from "react";
import styles from "./EDP.module.css";

function EDP() {
  return (
    <div className={styles.edpContentContainer}>
      A project designing a power tool for use by elderly people. The aim of the
      device was to help elderly people take better care of their outdoor
      spaces. Our product was designed to remove weeds from cracks in a patio or
      between paving slabs. It is designed for users with limited mobility and
      for those who struggling to bend over.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/EDP/ideation-sketches.webp"
        alt="Design sketches"
        className={styles.edpVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/EDP/sketch.webp"
        alt="Design sketches"
        className={styles.edpVisual}
      />
      Design and ideation drawings.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/EDP/cutaways.webp"
        alt="Cutaway renders"
        className={styles.edpVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/EDP/render-landscape.webp"
        alt="Rendering"
        className={styles.edpVisual}
      />
      Renderings of the final CAD model.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/EDP/slides.webp"
        alt="Examples presentation slides from slide deck"
        className={styles.edpVisual}
      />
      Example slides from the slide deck presentation.
      <br />
    </div>
  );
}

export default EDP;
