import React from "react";
import styles from "./GravitySketch.module.css";

function GravitySketch() {
  return (
    <div className={styles.procreateContentContainer}>
      <video
        playsInline
        autoPlay
        muted={true}
        loop
        className={styles.procreateVisual}
      >
        <source
          src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/GravitySketch/joined_video_c2ae36e7dfb54b95bd861bd72a209ce9.MP4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default GravitySketch;
