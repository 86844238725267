import React, { useEffect, useRef } from "react";
import p5 from "p5";

const Arrow = () => {
  const canvasRef = useRef(null);
  const p5InstanceRef = useRef(null);
  let arrowSize = 60;

  useEffect(() => {
    let middleY;
    let circleSpacing;

    const setup = (p) => {
      p.createCanvas(window.innerWidth, 300);
      p.background("#000");
      p.colorMode(p.RGB);

      middleY = p.height / 2;
      circleSpacing = p.width / 3;

      let circleCount = 3;

      for (let i = 0; i < circleCount; i++) {
        let x = p.width / 2 + (i - 1) * circleSpacing;
        let y = middleY;

        if (i === 0) {
          p.fill("#F01");
        } else if (i === 1) {
          p.fill("#00FF00");
          y = middleY + 100;
        } else if (i === 2) {
          p.fill("#0000FF");
        }

        drawArrow(p, x, y, arrowSize);
      }
    };

    const draw = (p) => {
      let scrollY = window.scrollY;
      let maxScrollConverge = p.windowHeight * 0.6;

      circleSpacing = p.map(scrollY, 0, maxScrollConverge, p.width / 3, 0);
      circleSpacing = p.constrain(circleSpacing, 0, p.width / 3);

      p.blendMode(p.BLEND);
      p.background("#000");
      let circleCount = 3;
      p.noFill();

      for (let i = 0; i < circleCount; i++) {
        let x = p.width / 2 + (i - 1) * circleSpacing;
        let y = middleY;
        let arrowSize = 60;

        if (i === 0) {
          p.stroke("#F01");
        } else if (i === 1) {
          p.stroke("#00FF00");
        } else if (i === 2) {
          p.stroke("#0000FF");
        }

        drawArrow(p, x, y, arrowSize);
      }

      p.blendMode(p.ADD);

      for (let i = 0; i < circleCount; i++) {
        let x = p.width / 2 + (i - 1) * circleSpacing;
        let y = middleY;
        let arrowSize = 60;

        if (i === 0) {
          p.stroke(255, 0, 0);
        } else if (i === 1) {
          p.stroke(0, 255, 0);
        } else if (i === 2) {
          p.stroke(0, 0, 255);
        }

        drawArrow(p, x, y, arrowSize);
      }
    };

    const drawArrow = (p, x, y, size) => {
      let arrowWidth = size * 1;
      let arrowHeight = size * 1;
      let arrowBase = size * 0.5;

      p.strokeWeight(15);

      p.strokeJoin(p.MITER);
      p.strokeCap(p.PROJECT);
      p.line(x, y + arrowHeight - 10, x, y - 150); // Vertical line (pointing down)
      p.line(x - arrowWidth / 2, y + arrowBase, x, y + arrowHeight); // Left slanting line
      p.line(x + arrowWidth / 2, y + arrowBase, x, y + arrowHeight); // Right slanting line
    };

    const windowResized = (p) => {
      // Resize the canvas when the window size changes
      p.resizeCanvas(window.innerWidth, 300);
    };

    if (!p5InstanceRef.current) {
      p5InstanceRef.current = new p5((p) => {
        p.windowResized = () => windowResized(p);
        p.setup = () => setup(p);
        p.draw = () => draw(p);
      }, canvasRef.current);
    }

    return () => {
      if (p5InstanceRef.current) {
        p5InstanceRef.current.remove();
        p5InstanceRef.current = null;
      }
    };
  }, []);

  return <div ref={canvasRef}></div>;
};

export default Arrow;
