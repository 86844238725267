const buttonData = [
  {
    text: "heycar",
    title: "Junior Front End Developer",
    subtitle: "June 2022 - Current",
    bulletPoints: [
      "Collaborated closely with a cross-functional team of designers, back end developers, and product managers to deliver a high-quality e-commerce web application.",
      "Developed and maintained responsive user interfaces using the JavaScript framework React.",
      "Employed Git and GitHub for version control, collaborating with team members to ensure smooth code integration and efficient workflow, also engaged in continuous integration and continuous deployment (CI/CD) practices and integrating GraphQL to efficiently fetch and manage data from various APIs.",
    ],
    isLast: false,
  },
  {
    text: "Audio Experience Lab",
    title: "Undergraduate Researcher and Designer",
    subtitle: "April - October 2021",
    bulletPoints: [
      "Worked as a member of the research team at the Audio Experience Design Labs (Imperial College London), designing and constructing test equipment for use in 3D spatial audio experiments. ",
      "Primary role was a solo-project designing and manufacturing a precise and heavy-duty rotary table that a rotated a human with <1.5° accuracy.",
      "I completed the full design process from ideation to mechanical / electronic design and manufacturing The equipment is currently being used for spatial audio research.",
    ],
    isLast: false,
  },
  {
    text: "Studio Drift",
    title: "Production and Design Internship",
    subtitle: "June - October 2020",
    bulletPoints: [
      "Design engineering internship at a world leading design studio based in Amsterdam. Working alongside artists and engineers to produce installations and art works for galleries and museums across the globe.",
      "I worked across all of their ongoing projects, mostly focusing on creating and implementing a production process for Drifter, a floating art installation that gives the illusion of a concrete monolith suspended in the air. Recently exhibited at The Shed, New York.",
    ],
    isLast: true,
  },
  // Add more buttons and their respective contents as needed
];

export default buttonData;
