const phrasesData = [
  { phrase: "3D modelling", category: "Engineer", color: "#009ee1" },
  { phrase: "Adobe Creative Suite", category: "Designer", color: "#ffed00" },
  { phrase: "Arduino", category: "Developer", color: "#e3007a" },
  { phrase: "Blender", category: "Designer", color: "#ffed00" },
  { phrase: "C languages", category: "Developer", color: "#e3007a" },
  {
    phrase: "Design thinking & ideation",
    category: "Designer",
    color: "#ffed00",
  },
  {
    phrase: "Electronics / Mechatronics prototyping",
    category: "Engineer",
    color: "#009ee1",
  },
  { phrase: "Figma", category: "Designer", color: "#ffed00" },
  { phrase: "Finite Element Analysis", category: "Engineer", color: "#009ee1" },
  { phrase: "Front end development", category: "Developer", color: "#e3007a" },
  { phrase: "Generative design", category: "Designer", color: "#ffed00" },
  { phrase: "Git", category: "Developer", color: "#e3007a" },
  { phrase: "Javascript", category: "Developer", color: "#e3007a" },
  { phrase: "Matlab", category: "Engineer", color: "#009ee1" },
  { phrase: "Onshape", category: "Engineer", color: "#009ee1" },
  { phrase: "p5.js", category: "Developer", color: "#e3007a" },
  {
    phrase: "Prototyping & concept development",
    category: "Designer",
    color: "#ffed00",
  },
  { phrase: "Python", category: "Developer", color: "#e3007a" },
  { phrase: "React", category: "Developer", color: "#e3007a" },
  { phrase: "Solidworks", category: "Engineer", color: "#009ee1" },
  { phrase: "UX & system design", category: "Designer", color: "#ffed00" },
];

export default phrasesData;
