import React, { useState, useEffect } from "react";
import styles from "./IOT.module.css";

function IOT() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.iotContentContainer}>
      An IOT Project that used heat and motion sensors to track sleep quality
      and control bed temperature. The project involved using an arduino in
      conjunction with the IOT web service ThingSpeak.
      {isTablet ? (
        <video controls className={styles.iotVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/IOT/IOT.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video controls className={styles.iotVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/IOT/output.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/IOT/output.webp"
        }
        alt="System diagram of the IOT system"
        className={styles.iotVisual}
      />
    </div>
  );
}

export default IOT;
