import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import axios from "axios";

import { validateUKPostcode, getImageForModel } from "../utils/Utils";

import styles from "./GoogleMap.module.css";

export const GoogleMapContainer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [postcode, setPostcode] = useState("");
  const [maskUrl, setMaskUrl] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [backendError, setBackendError] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 51.533, lng: -0.0799 });
  const [zoom, setZoom] = useState(18);
  const [mapChanged, setMapChanged] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [showInterface, setShowInterface] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newLatLng, setNewLatLng] = useState({ lat: 51.533, lng: -0.0799 });

  const [imageReloadKey, setImageReloadKey] = useState(0);

  const totalArea = 13300;
  const estimatedAvailableArea = (percentage / 100) * totalArea;
  const estimatedOutput = estimatedAvailableArea * 0.9;

  let maskSize = windowWidth >= 768 ? 640 : 320;

  function pixelsToMeters(pixels, zoom) {
    const EARTH_RADIUS_METERS = 6378137;
    const circumference = 2 * Math.PI * EARTH_RADIUS_METERS;
    const scale = Math.pow(2, zoom);
    const metersPerPixel = circumference / (256 * scale);
    return pixels * metersPerPixel;
  }

  function metersToLatLng(distanceInMeters, centerLatLng) {
    const lngDistance =
      distanceInMeters /
      (111111 * Math.cos(centerLatLng.lat * (Math.PI / 180))); // Approximate longitude degrees per meter at given latitude
    if (windowWidth >= 1024)
      return {
        lat: centerLatLng.lat,
        lng: centerLatLng.lng - lngDistance,
      };
    return {
      lat: centerLatLng.lat - lngDistance,
      lng: centerLatLng.lng,
    };
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      maskSize = window.innerWidth >= 768 ? 640 : 320;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Adjust zoom level based on window width
    setZoom(windowWidth <= 768 ? 18 : 19);
  }, [windowWidth]);

  const distanceInPixels =
    windowWidth >= 1440
      ? 0
      : windowWidth >= 1024
      ? windowWidth * 0.1
      : windowHeight * 0.02;

  useEffect(() => {
    const distanceInMeters = pixelsToMeters(distanceInPixels, zoomLevel);
    const { lat, lng } = newLatLng; // Assuming newLatLng is the state you want to use
    const updatedLatLng = metersToLatLng(distanceInMeters, { lat, lng });
    setNewLatLng(updatedLatLng);
  }, [windowWidth, windowHeight]); // Add any other dependencies you may need

  const zoomLevel = 19;

  const handlePostcodeSubmit = async (e) => {
    if (!validateUKPostcode(postcode)) {
      setPostcodeError("Invalid UK postcode");
      return;
    }

    setLoading(true);

    try {
      setPostcodeError("");

      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${postcode}&key=271ab7d1bf964064ad3ed7efaf51a321`
      );

      const { results } = response.data;

      if (results.length > 0) {
        const { lat, lng } = results[0].geometry;
        const distanceInPixels =
          windowWidth >= 1440
            ? 0
            : windowWidth >= 1024
            ? windowWidth * 0.1
            : windowHeight * 0.02;
        const distanceInMeters = pixelsToMeters(distanceInPixels, zoomLevel);
        const newLatLng = metersToLatLng(distanceInMeters, { lat, lng });
        setNewLatLng(newLatLng);
        setMapCenter(newLatLng);
        setZoom(windowWidth <= 768 ? 18.01 : 19.01);

        const result = await getImageForModel(lat, lng);
        const maskUrl = result.maskUrl;
        const percentage = result.percentage;
        setMaskUrl(maskUrl);
        setPercentage(percentage);
        setImageReloadKey((prevKey) => prevKey + 1);
        setMapChanged(false);
        setBackendError(false);
        setLoading(false);
        setShowInterface(true);
      } else {
        setBackendError(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setBackendError(true);
    }
  };

  const handleZoomChanged = () => {
    setZoom(windowWidth <= 768 ? 18 : 19);
    setMapChanged(true);
    setShowInterface(false);
  };

  const handleDragEnd = () => {
    setMapChanged(true);
  };

  const maskBorderLeft =
    windowWidth >= 1440
      ? `calc(50%)`
      : windowWidth >= 1024
      ? `calc(50% + ${windowWidth * 0.17}px)`
      : `calc(50%)`;

  const maskBorderTop =
    windowWidth >= 1024
      ? `calc(50%)`
      : `calc(${document.body.scrollHeight / 2}px - ${windowWidth * 0.06}px)`;

  return (
    <div className={styles.map}>
      {backendError && <div className={styles.backendError}>No Connection</div>}
      <div className={styles.information}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Solar Potential</div>
          <div className={styles.searchWrapper}>
            <div className={styles.searchBar}>
              <input
                type="text"
                placeholder="Enter UK postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
              <button onClick={handlePostcodeSubmit}>Search</button>
            </div>
            {postcodeError && (
              <div className={styles.searchError}>Invalid postcode</div>
            )}
          </div>
        </div>

        <div
          className={`${styles.metrics} ${
            showInterface && !mapChanged ? styles.show : ""
          }`}
        >
          <div className={styles.text}>
            Building coverage:
            <div className={styles.metric}>{percentage}%</div>
          </div>
          <div className={styles.text}>
            Estimated available surface area:
            <div className={styles.metric}>
              {estimatedAvailableArea.toFixed(2)} m<sup>2</sup>
            </div>
          </div>
          <div className={styles.text}>
            Solar potential:
            <div className={styles.metric}>
              {estimatedOutput.toFixed(2)} MWh p/y
            </div>
          </div>
        </div>
      </div>
      <div className={styles.gradientOverlay}></div>
      <div
        className={styles.gradientOverlay2}
        style={{
          top: "calc(100vh - 40vw)",
        }}
      ></div>

      <div className={styles.overlay}></div>
      <LoadScript googleMapsApiKey="AIzaSyBXfyqFXxCJUwu322bJueB4ORuZZKGpmQ4">
        <GoogleMap
          mapContainerStyle={{ height: "100vh", width: "100%" }}
          zoom={zoom}
          center={mapCenter}
          disableDefaultUI={true}
          onZoomChanged={handleZoomChanged}
          onDragEnd={handleDragEnd}
          options={{
            mapTypeControl: false,
            mapTypeId: "satellite",
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            scaleControl: false,
            rotateControl: false,
            tilt: 0,
            disableDefaultUI: 0,
          }}
        ></GoogleMap>
      </LoadScript>

      <div
        className={`${styles.loadingIcon} ${loading ? styles.show : ""}`}
        style={{
          left: maskBorderLeft,
          top: maskBorderTop,
        }}
      >
        <div className={styles.metric}>Loading...</div>
      </div>

      <div
        className={`${styles.maskBorder}  ${
          showInterface && !mapChanged ? styles.show : ""
        }`}
        style={{
          left: maskBorderLeft,
          top: maskBorderTop,
          width: maskSize,
          height: maskSize,
        }}
      >
        <img
          src={`${maskUrl}?key=${imageReloadKey}`}
          className={`${styles.mask}  ${
            showInterface && !mapChanged ? styles.showMask : ""
          }`}
          style={{ width: maskSize, height: maskSize }}
          alt="mask"
        />
      </div>
    </div>
  );
};
