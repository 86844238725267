import React from "react";
import styles from "./Renders.module.css";

function Renders() {
  return (
    <div className={styles.procreateContentContainer}>
      Renders made in keyshot, from a project designing a gym accessory.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Render/pebble-1.webp"
        alt="Renderings of a gym accessory"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Render/pebble-2.webp"
        alt="Renderings of a gym accessory"
        className={styles.procreateVisual}
      />
      Renders made in keyshot, from a project designing a minature robot.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Render/wheg-1.webp"
        alt="Renderings of a minature robot"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Render/wheg-2.webp"
        alt="Renderings of a minature robot"
        className={styles.procreateVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Render/wheg-3.webp"
        alt="Renderings of a minature robot"
        className={styles.procreateVisual}
      />
    </div>
  );
}

export default Renders;
