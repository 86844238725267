import React, { useState } from "react";

import PhraseComponent from "./PhraseComponent/PhraseComponent";
import phrasesData from "./PhraseComponent/PhrasesData";

import styles from "./Skills.module.css";

const Skills = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? "all" : category
    );
  };

  return (
    <div className={styles.skillsContainer}>
      <div>
        {phrasesData.map((phraseObj, index) => {
          return (
            <PhraseComponent
              key={index}
              phrase={phraseObj.phrase}
              category={phraseObj.category}
              color={phraseObj.color}
              selectedCategory={selectedCategory}
            />
          );
        })}
      </div>
      <div className={styles.skillsSubtitle}>Filter by area:</div>
      <div className={styles.skillsButtonsContainer}>
        <button
          onClick={() => handleCategoryChange("Engineer")}
          className={`${styles.skillButton} ${styles.engineering} ${
            selectedCategory === "Engineer" ? styles.active : ""
          }`}
        >
          Engineering
        </button>
        <button
          onClick={() => handleCategoryChange("Designer")}
          className={`${styles.skillButton} ${styles.design} ${
            selectedCategory === "Designer" ? styles.active : ""
          }`}
        >
          Design
        </button>
        <button
          onClick={() => handleCategoryChange("Developer")}
          className={`${styles.skillButton} ${styles.development} ${
            selectedCategory === "Developer" ? styles.active : ""
          }`}
        >
          Development
        </button>
      </div>
    </div>
  );
};

export default Skills;
