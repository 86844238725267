import React from "react";
import { GoogleMapContainer } from "./GoogleMaps/GoogleMap";

function SolarPotential() {
  return (
    <div className="App">
      <GoogleMapContainer />
    </div>
  );
}

export default SolarPotential;
