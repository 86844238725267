import axios from "axios";

export const validateUKPostcode = (postcode) => {
  const postcodeRegex = /^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/i;
  return postcodeRegex.test(postcode);
};

const calculateBoundingBox = (lat, lng, width, height, zoom) => {
  const scale = 2 ** zoom;
  const northEastLat = lat + (height / 2 / 256) * (1 / scale);
  const northEastLng = lng + (width / 2 / 256) * (1 / scale);
  const southWestLat = lat - (height / 2 / 256) * (1 / scale);
  const southWestLng = lng - (width / 2 / 256) * (1 / scale);

  return {
    northEast: { lat: northEastLat, lng: northEastLng },
    southWest: { lat: southWestLat, lng: southWestLng },
  };
};

export const handleBulkPostcodeSubmit = async () => {
  const postcodesToLoad = ["GL56 9SD"];

  for (const postcode of postcodesToLoad) {
    if (!validateUKPostcode(postcode)) {
      console.error(`Invalid UK postcode: ${postcode}`);
      continue;
    }
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${postcode}&key=271ab7d1bf964064ad3ed7efaf51a321`
      );
      const { results } = response.data;

      if (results.length > 0) {
        const { lat, lng } = results[0].geometry;

        const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=19&size=640x640&maptype=satellite&key=AIzaSyBXfyqFXxCJUwu322bJueB4ORuZZKGpmQ4`;
        await axios.post("http://localhost:3001/api/saveImage", {
          postcode,
          imageUrl,
        });
      } else {
        console.error(`Location not found for ${postcode}`);
      }
    } catch (error) {
      console.error(`Error fetching data for ${postcode}:`, error);
    }
  }
};

export const getImageForModel = async (lat, lng) => {
  try {
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=19&size=640x640&maptype=satellite&key=AIzaSyBXfyqFXxCJUwu322bJueB4ORuZZKGpmQ4`;
    // Send a POST request to your server to save the image
    // Send a POST request to your server to save the image and generate the mask
    const response = await axios.post(
      "https://sp-backend2-e3c74f61238c.herokuapp.com/api/saveIndividualImage",
      // "http://localhost:3001/api/saveIndividualImage",

      {
        imageUrl,
      }
    );

    if (response.data.success) {
      const maskImageUrl = response.data.maskImageUrl;
      const percentage = response.data.percentage;
      return { maskUrl: maskImageUrl, percentage: percentage };
    }
  } catch (error) {
    console.error(`Error fetching image:`, error);
  }
};
