import React, { useState, useEffect } from "react";
import styles from "./AirPollutionInstallation.module.css";

function AirPollutionInstallation() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.airPollutionContentContainer}>
      <div className={styles.airPollutionTextBox}>
        In this project I designed an exhibition installation for the Great
        Exhibition Road Festival, a science and arts festival held in South
        Kensington, London. The installation aimed to educate viewers about air
        pollution in London using novel computer generated visualisations. An in
        depth research process allowed common knowledge gaps and misconceptions
        that Londoners had about air pollution to be identified, in order to
        inform the design of the graphics.
      </div>
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/exhibition.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/exhibition.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      <div className={styles.airPollutionTextBox}>
        Air pollution sensors were used to create a live graphical visualisation
        of different particulates present in the air. The visual was a p5.js
        graphic which had was driven by data from the sensors via an arduino.
      </div>
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/side-two.webp"
        }
        alt="The second side of the installation, showing air pollution distribution across London"
        className={styles.airPollutionVisual}
      />
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/PollutionData.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/Pollution+Data.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Air pollution levels vary dramatically across an area at a human scale -
      parallel streets may have drastically different air pollution levels. This
      graphic aimed to highlight how air pollution levels change at street level
      due to the structure of the urban environment and the time of day.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/SideOneDemo.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/SideOneDemo.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Using data to visualise air pollution and educate viewers about pollutants
      and their health effects
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/sensor-demo.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/sensor-demo.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Early prototype of the arduino set up, feeding data to a live p5.js
      sketch.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/sketches.webp"
        }
        alt="Ideation sketches for the installation"
        className={styles.airPollutionVisual}
      />
      Ideation sketches.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/LiveData.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.airPollutionVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/LiveData.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      As part of this project I developed a portable air quality sensor to
      highlight pollution levels at a human scale. I attached the sensor to a
      bicycle and recorded live pollution levels and footage simultaneously,
      whilst cycling around London.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/sensor-setup.webp"
        }
        alt="Air quality sensor electronics in a 3D printed case"
        className={styles.airPollutionVisual}
      />
      Air quality sensor set up.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/bicycle.webp"
        }
        alt="Air quality sensor strapped to bicycle"
        className={styles.airPollutionVisual}
      />
      Air quality sensor attahced to bicycle.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/exhibition-kid.webp"
        }
        alt="Installation in situ"
        className={styles.airPollutionVisual}
      />
    </div>
  );
}

export default AirPollutionInstallation;
