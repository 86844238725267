import React from "react";
import styles from "./Content.module.css";

function Content({ title, subtitle, bulletPoints }) {
  return (
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.bulletPoints}>
        <ul>
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Content;
