import React, { useEffect } from "react";

import styles from "./Homepage.module.css";

import Arrow from "../../Arrow";

import Experiences from "./Experiences/Experiences.view";
import Projects from "./Projects/Projects";
import Skills from "./Skills/Skills";
import Contact from "./Contact/Contact";

import ThreePoints from "../../icons/threePoints";
import FourPoints from "../../icons/fourPoints";
import FivePoints from "../../icons/fivePoints";
import SixPoints from "../../icons/sixPoint";

import { useLocation } from "react-router-dom";

function Homepage() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#footer") {
      window.onload = () => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      };
    }
  }, [location]);

  function scrollToExperience() {
    const experienceSection = document.getElementById("experience");

    if (experienceSection) {
      experienceSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <div>
      <div className={styles.section1}>
        <div className={styles.text}>
          <div>
            <span className={styles.italic}>Hello,</span>
            &nbsp;welcome to my portfolio.
          </div>
          <div>I am a designer, developer and engineer.</div>
          <div>
            I have a passion for doing things
            <span className={styles.italic}>&nbsp;differently.</span>
          </div>
          <div className={styles.grey}>
            <div>Scroll down to find out more</div>
          </div>
        </div>
        <div
          className={styles.arrows}
          onClick={(e) => {
            e.preventDefault();
            scrollToExperience();
          }}
        >
          <Arrow />
        </div>
      </div>
      <div className={styles.sectionContainer} id={"experience"}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <div className={styles.icon}>
              <ThreePoints number={"1."} />
            </div>
            <div className={styles.sectionTitleText}>Experience</div>
          </div>
          <Experiences />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.section}>
          <div className={`${styles.sectionTitle} ${styles.right}`}>
            <div className={`${styles.sectionTitleText}  ${styles.right}`}>
              Projects
            </div>
            <div className={`${styles.icon} ${styles.right}`}>
              <FourPoints number={"2."} />
            </div>
          </div>
          <Projects />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <div className={styles.icon}>
              <FivePoints number={"3."} />
            </div>
            <div className={styles.sectionTitleText}>Skills</div>
          </div>
          <Skills />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.section}>
          <div className={` ${styles.sectionTitle} ${styles.right}`}>
            <div className={styles.sectionTitleText}>Contact Me</div>
            <div className={`${styles.icon} ${styles.right}`}>
              <SixPoints number={"4."} />
            </div>
          </div>
          <Contact />
        </div>
      </div>
      <div className={styles.footer} id="footer">
        <p>Designed in Figma, built with React.</p>
        <p>Matthew Speechley, 2023.</p>
      </div>
    </div>
  );
}

export default Homepage;
