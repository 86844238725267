import React from "react";

const SpikeyStarRight = ({ name, name1, name2, color }) => {
  return (
    <svg
      width="268"
      height="110"
      viewBox="0 0 268 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.819 20.2192L191.678 1.84444L192.851 18.9627L192.931 20.1325L194.073 19.8687L241.326 8.96042L219.728 27.4012L217.561 29.2513L220.409 29.1612L262.414 27.8326L223.5 43.7039L220.862 44.7799L223.594 45.5887L250.826 53.6502L203.221 62.9045L202.07 63.1282L202.473 64.2295L208.364 80.3447L164.477 79.771L164.041 79.7653L163.74 80.0806L144.127 100.636L117.849 89.8349L117.446 89.6693L117.051 89.8517L77.1908 108.226L76.0184 91.1082L75.9383 89.9384L74.7958 90.2021L27.5428 101.11L49.1414 82.6697L51.3083 80.8196L48.4605 80.9097L6.45532 82.2383L45.3693 66.367L48.0076 65.291L45.2755 64.4822L18.0437 56.4206L65.6483 47.1664L66.7992 46.9426L66.3966 45.8414L60.5051 29.7262L104.393 30.2999L104.828 30.3056L105.129 29.9903L124.742 9.43485L151.02 20.2359L151.423 20.4016L151.819 20.2192Z"
        fill="#000"
        stroke={color}
        strokeWidth="2"
      />

      <text
        x="50%"
        y="55%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        fontSize="16"
        fontStyle="italic"
        fontWeight="800"
        transform={`rotate(-12, 45, 55)`}
      >
        <tspan x="50%" y="65%">
          {name}
        </tspan>
        <tspan x="50%" dy="0em">
          {name1}
        </tspan>
        <tspan x="50%" dy="1.2em">
          {name2}
        </tspan>
      </text>
    </svg>
  );
};

export default SpikeyStarRight;
