import React, { useState, useEffect } from "react";
import styles from "./Experiences.module.css";
import Content from "./Content/Content";
import buttonData from "./ButtonData.constant";

function Experiences() {
  const [activeButton, setActiveButton] = useState(-1);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);

  useEffect(() => {
    setActiveButton(-1);

    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex === activeButton ? null : buttonIndex);
  };

  const buttons = [
    {
      text: "heycar",
      content: (
        <Content
          title={buttonData[0].title}
          subtitle={buttonData[0].subtitle}
          bulletPoints={buttonData[0].bulletPoints}
        />
      ),
      colorClass: "cyan",
      isLast: false,
    },
    {
      text: "Audio Experience Lab",
      content: (
        <Content
          title={buttonData[1].title}
          subtitle={buttonData[1].subtitle}
          bulletPoints={buttonData[1].bulletPoints}
        />
      ),
      colorClass: "magenta",
      isLast: false,
    },
    {
      text: "Studio Drift",
      content: (
        <Content
          title={buttonData[2].title}
          subtitle={buttonData[2].subtitle}
          bulletPoints={buttonData[2].bulletPoints}
        />
      ),
      colorClass: "yellow",
      isLast: true,
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.buttonList}>
          {buttons.map((button, index) => (
            <div key={button.title}>
              <div className={`${activeButton === index ? styles.active : ""}`}>
                <button
                  className={`${styles.button} ${
                    activeButton === index ? styles.active : ""
                  } ${styles[button.colorClass]} ${
                    button.isLast ? styles.lastButton : ""
                  } ${isLargeScreen ? styles.largeScreen : ""}`}
                  onClick={() => handleButtonClick(index)}
                >
                  {button.text}
                </button>
              </div>
              {activeButton === index || isLargeScreen ? (
                <>
                  <div
                    className={`${styles.content} ${
                      activeButton === index ? styles.active : ""
                    } ${button.isLast ? styles.lastButton : ""} ${
                      isLargeScreen ? styles.largeScreen : ""
                    }`}
                    onClick={
                      activeButton !== index
                        ? () => handleButtonClick(index)
                        : null
                    }
                  >
                    {button.content}
                    <div
                      className={` ${styles.buttonOverlay} ${
                        activeButton === index ? styles.active : ""
                      }`}
                    ></div>
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Experiences;
