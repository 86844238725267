import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./index.css";
import Homepage from "./pages/Homepage/Homepage.view";
import Projects from "./pages/Projects/Projects.view";
import { RecoilRoot } from "recoil";
import ScrollToTopOnNavigate from "./utils/ScrollToTopOnNavigate";
import SolarPotential from "./pages/SolarPotential/SolarPotential.view";
import Header from "./components/Header";
import { Analytics } from "@vercel/analytics/react";

const root = createRoot(document.getElementById("root"));

function ConditionalHeader() {
  const isSolarPotentialRoute = window.location.pathname === "/solar-potential";
  if (!isSolarPotentialRoute) {
    return <Header />;
  }
  return null;
}

function App() {
  return (
    <Router>
      <Analytics />
      <ScrollToTopOnNavigate />
      <RecoilRoot>
        <React.StrictMode>
          <ConditionalHeader />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/solar-potential" element={<SolarPotential />} />
          </Routes>
        </React.StrictMode>
      </RecoilRoot>
    </Router>
  );
}

root.render(<App />);
