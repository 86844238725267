import React, { useState, useEffect } from "react";
import styles from "./Reach.module.css";

function Reach() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  const handleReportClick = () => {
    const path =
      "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/documents/Reach_Report.pdf";
    window.open(path, "_blank");
  };
  const handlePitchDeckClick = () => {
    const path =
      "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/documents/REACH.pdf";
    window.open(path, "_blank");
  };

  return (
    <div className={styles.reachContentContainer}>
      This group project explored the future of tele-robotics for Lunar
      exploration. An investigation into the technological advancements in the
      field of lunar exploration that are likely to take place over the next
      twenty years was made. A literature review was then conducted to explore
      existing technologies and uncover areas of opportunity. This led to the
      conclusion that an easily accessible system allowing for remote
      exploration of the Lunar surface will be required. We developed a system
      which builds a virtual copy of the Lunar environment using AI and live
      data, which allows the robot to be controlled in real time with VR.
      Planned movements are visualised with a graphical overlay and haptic
      feedback is used to indicate if a command might be hazardous.
      <br />
      <br />
      The outputs of this project were a video, a slide deck and a ten page
      report.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.reachVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/Controller.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video playsinline autoPlay muted loop className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/Controller.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      A demonstration of the view through the VR headset, showing both rendered
      moon surface and simulated controllers in one view.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.reachVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-headset.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video playsinline autoPlay muted loop className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-headset.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      The VR head set will give a 360 degree view of the lunar surface.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.reachVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-overlay-graphics.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video playsinline autoPlay muted loop className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-overlay-graphics.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      The rendered moon surface will be modifiable, creating useful graphic
      overlays.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.reachVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-blender-clips.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video playsinline autoPlay muted loop className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-blender-clips.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Blender was used to generate visuals for the video.
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.reachVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-logo.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video playsinline autoPlay muted loop className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach-logo.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Logo designed and animated in After Effects
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/lunar-infrastructure.webp"
        }
        alt="Example slide from slide deck"
        className={styles.reachVisual}
      />
      An example pitch deck slide, with graphics made in Illustrator.
      {isTablet ? (
        <video controls className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video controls className={styles.reachVisual}>
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/reach.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Video summary.
      <div className={styles.reachFileContainer}>
        <button
          onClick={handleReportClick}
          className={`${styles.contactButton} ${styles.email}`}
        >
          Report
        </button>
        <button
          onClick={handlePitchDeckClick}
          className={`${styles.contactButton} ${styles.cv}`}
        >
          Pitch Deck
        </button>
      </div>
    </div>
  );
}

export default Reach;
