import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.44 1L24 11.5688M24 11.5688L13.44 22.1376M24 11.5688H0"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowRight;
