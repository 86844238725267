import React from "react";

const SpikeyStarLeft = ({ name, name1, name2, color }) => {
  return (
    <svg
      width="268"
      height="110"
      viewBox="0 0 268 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M164.451 30.2689L208.338 29.6497L202.463 45.771L202.061 46.8727L203.213 47.0952L250.827 56.3002L223.603 64.3899L220.872 65.2015L223.511 66.2748L262.442 82.1058L220.435 80.8207L217.587 80.7336L219.756 82.5814L241.374 101L194.11 90.1404L192.967 89.8779L192.888 91.0477L191.733 108.167L151.854 89.8338L151.458 89.6517L151.056 89.8178L124.789 100.646L105.155 80.111L104.854 79.796L104.418 79.8021L60.5311 80.4213L66.406 64.3L66.8074 63.1984L65.6562 62.9758L18.0421 53.7709L45.2656 45.6811L47.9968 44.8695L45.3574 43.7962L6.42699 27.9652L48.4335 29.2503L51.2814 29.3374L49.1126 27.4896L27.495 9.07121L74.7592 19.9306L75.9019 20.1932L75.9808 19.0233L77.1355 1.90383L117.014 20.2373L117.41 20.4193L117.813 20.2532L144.08 9.42492L163.714 29.96L164.015 30.275L164.451 30.2689Z"
        fill="#000"
        stroke={color}
        strokeWidth="2"
      />

      <text
        x="50%"
        y="20%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        fontSize="16"
        fontStyle="italic"
        fontWeight="800"
        transform={`rotate(12, 45, 55)`}
      >
        <tspan x="50%" y="33%">
          {name}
        </tspan>
        <tspan x="50%" dy="0em">
          {name1}
        </tspan>
        <tspan x="50%" dy="1.2em">
          {name2}
        </tspan>
      </text>
    </svg>
  );
};

export default SpikeyStarLeft;
