import React from "react";

const ThreePoints = ({ number }) => {
  return (
    <svg
      width="68"
      height="67"
      viewBox="0 0 68 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5598 6.56035C15.5734 0.435787 23.6753 -1.73512 26.7493 3.56213L39.7695 25.9991C40.2936 26.9023 41.0444 27.6531 41.9476 28.1772L64.3846 41.1974C69.6818 44.2714 67.5109 52.3734 61.3864 52.3869L35.4452 52.4442C34.401 52.4466 33.3755 52.7213 32.47 53.2415L9.97563 66.1624C4.66483 69.2129 -1.26621 63.2819 1.78435 57.9711L14.7053 35.4767C15.2254 34.5712 15.5002 33.5457 15.5025 32.5015L15.5598 6.56035Z"
        fill="#009300"
      />
      <text
        x="45%"
        y="55%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20"
        fontWeight="600"
        fill="white"
      >
        {number}
      </text>
    </svg>
  );
};

export default ThreePoints;
