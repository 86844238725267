import React from "react";

const FivePoints = ({ number }) => {
  return (
    <svg
      width="70"
      height="73"
      viewBox="0 0 70 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1802 7.18366C22.7786 0.657198 31.6241 -1.71296 34.5395 4.13996L40.7656 16.6393C42.1534 19.4256 45.4416 20.6878 48.3373 19.5458L61.3279 14.423C67.4108 12.0242 72.3984 19.7045 67.7328 24.2858L57.7692 34.0696C55.5481 36.2506 55.3638 39.7678 57.3447 42.169L66.2311 52.9407C70.3922 57.9846 64.6291 65.1014 58.8303 62.0799L46.4464 55.6273C43.6858 54.1889 40.2838 55.1005 38.6123 57.7264L31.1138 69.5065C27.6026 75.0226 19.0532 71.7408 20.1349 65.2921L22.4449 51.5203C22.9598 48.4504 21.0415 45.4966 18.0276 44.7183L4.50692 41.2271C-1.82424 39.5923 -1.34496 30.4473 5.1224 29.4832L18.934 27.4245C22.0127 26.9655 24.2292 24.2284 24.038 21.1215L23.1802 7.18366Z"
        fill="#E3007A"
      />

      <text
        x="53%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20"
        fontWeight="600"
        fill="white"
      >
        {number}
      </text>
    </svg>
  );
};

export default FivePoints;
