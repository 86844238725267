import React, { useState, useEffect } from "react";
import styles from "./Wheatear.module.css";

function Wheatear() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.wheatearContentContainer}>
      A portfolio website for a small craft business. Website available at{" "}
      <a
        href="http://www.wheatear-strawcraft.co.uk"
        target="_blank"
        className={styles.link}
      >
        www.wheatear-strawcraft.co.uk
      </a>
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.wheatearVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Wheatear/Untitled+2.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.wheatearVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Wheatear/Untitled+2.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.wheatearVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Wheatear/Screen+Recording+2023-11-06+at+19.03.15.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.wheatearVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Wheatear/Screen+Recording+2023-11-06+at+19.03.15.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default Wheatear;
