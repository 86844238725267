import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import RGBsixty from "../icons/RGBsixty.js";
import RGBeighty from "../icons/RGBeighty.js";
import { Link } from "react-router-dom";

function Header() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDesktop = screenWidth >= 1024;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLinkClick = () => {
    closeModal();
  };

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={styles.header}>
      <Link to="/" className={styles.nameLink}>
        <div className={styles.name}>
          Matthew
          <br />
          Speechley
        </div>
      </Link>
      <div className={styles.logo} onClick={openModal}>
        {isDesktop ? <RGBeighty /> : <RGBsixty />}
      </div>
      {isModalOpen && (
        <div className={styles.modal} onClick={closeModal}>
          <div className={styles.modalContent} onClick={handleModalClick}>
            <button className={styles.closeButton} onClick={closeModal}>
              X
            </button>
            <div className={styles.modalContentList}>
              <Link
                to="/"
                className={`${styles.modalContentListItem} ${styles.contentListItemHome}`}
                onClick={handleLinkClick}
              >
                Home
              </Link>
              <Link
                to="/projects"
                className={`${styles.modalContentListItem} ${styles.contentListItemProjects}`}
                onClick={handleLinkClick}
              >
                Projects
              </Link>
              <a
                href="/#footer"
                className={`${styles.modalContentListItem} ${styles.contentListItemContact}`}
                onClick={handleLinkClick}
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
