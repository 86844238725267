import React from "react";
import styles from "./StudioDrift.module.css";

function StudioDrift() {
  return (
    <div className={styles.studioDriftContentContainer}>
      A three and a half month internship with Studio Drift, based in Amsterdam.
      I worked across all of their ongoing projects and gained a deep insight
      into the intersection between art, design and engineering, as well as the
      industry surrounding this highly specialised area. I primarily worked on
      the Drifter project, pictured, where I took on the role of production
      supervisor. Over the course of my internship a new design was ideated,
      prototyped, tested, refined and produced.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/StudioDrift/development3.png"
        alt="Drifter art piece being built"
        className={styles.studioDriftVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/StudioDrift/workshop.png"
        alt="Drifter art piece being built"
        className={styles.studioDriftVisual}
      />
      Key responsibilities included CAD design, product testing and management
      over a team of artists who completed a final finished piece.
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/StudioDrift/exhibition1.jpg"
        alt="Drifter art piece being exhibited"
        className={styles.studioDriftVisual}
      />
      <img
        src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/StudioDrift/exhibition2.jpg"
        alt="Drifter art piece being exhibited"
        className={styles.studioDriftVisual}
      />
      'Drifters' was exhibited at The Shed, NYC.
    </div>
  );
}

export default StudioDrift;
