import { atom } from "recoil";
import { development, projects } from "../pages/Projects/Projects.constants";

const defaultSelectedProject =
  window.innerWidth >= 1024 ? development[0] : null;

export const selectedProjectState = atom({
  key: "selectedProjectState",
  default: defaultSelectedProject,
});
