import React from "react";
import styles from "./Contact.module.css";

const Contact = () => {
  const handleEmailClick = () => {
    const recipientEmail = "matthew.speechley@outlook.com";
    const mailtoLink = `mailto:${recipientEmail}`;
    window.location.href = mailtoLink;
  };

  const handleCvClick = () => {
    const cvPath =
      "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/documents/CV.pdf";
    window.open(cvPath, "_blank");
  };

  return (
    <div className={styles.contactContainer}>
      <button
        onClick={handleEmailClick}
        className={`${styles.contactButton} ${styles.email}`}
      >
        Email
      </button>
      <button
        onClick={handleCvClick}
        className={`${styles.contactButton} ${styles.cv}`}
      >
        CV
      </button>
    </div>
  );
};

export default Contact;
