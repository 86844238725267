import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import styles from "./Projects.module.css";
import SpikeyStarLeft from "../../../icons/spikeyStarLeft";
import SpikeyStarRight from "../../../icons/spikeyStarRight";
import { Link } from "react-router-dom";
import { selectedProjectState } from "../../../atoms/SelectedProject.atom";
import { development, projects } from "../../Projects/Projects.constants";

function Projects() {
  const [selectedProject, setSelectedProject] =
    useRecoilState(selectedProjectState);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.projectContainer}>
      <div className={`${styles.rowContainer} ${styles.zero}`}>
        <div className={styles.imageContainer}>
          <Link
            to="/projects"
            onClick={() => setSelectedProject(development[0])}
          >
            <div className={`${styles.projectsTitle} ${styles.odd}`}>
              {isMobile ? (
                <SpikeyStarRight color="#00FFFF" name="Solar Potential" />
              ) : (
                <SpikeyStarLeft color="#00FFFF" name="Solar Potential" />
              )}
            </div>
            {isTablet ? (
              <video
                playsInline
                autoPlay
                muted={true}
                loop
                className={`${styles.image} ${styles.zero}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/phone.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video
                playsinline
                autoPlay
                muted
                loop
                className={`${styles.image} ${styles.zero}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/tablet.webm"
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </Link>
        </div>
        <div className={styles.projectsTextBox}>
          <div className={styles.projectsSubtitle}>
            Autumn 2023 - Spring 2024
          </div>
          <div className={styles.projectsText}>
            An interactive webapp for identifying potential solar power output
            of buildings from satellite images. Based around a custom built
            image recognition AI model.
          </div>
          <Link to="/projects" onClick={() => setSelectedProject(projects[0])}>
            <div
              className={`${styles.projectsFindOutMore} ${styles.lightBlueLink}`}
            >
              Find out more
            </div>
          </Link>
        </div>
      </div>
      <div className={` ${styles.rowContainer} ${styles.one}`}>
        {!isMobile && (
          <div className={`${styles.projectsTextBox} ${styles.left}`}>
            <div className={styles.projectsSubtitle}>Summer 2023</div>
            <div className={styles.projectsText}>
              Portfolio website for a small crafts business
            </div>
            <Link
              to="/projects"
              onClick={() => setSelectedProject(development[1])}
            >
              <div
                className={`${styles.projectsFindOutMore} ${styles.greenLink}`}
              >
                Find out more
              </div>
            </Link>
          </div>
        )}
        <div className={styles.imageContainer}>
          <Link
            to="/projects"
            onClick={() => setSelectedProject(development[1])}
          >
            <div className={`${styles.projectsTitle} ${styles.even}`}>
              {isMobile ? (
                <SpikeyStarLeft color="#00FF00" name="Wheatear Strawcraft" />
              ) : (
                <SpikeyStarRight color="#00FF00" name="Wheatear Strawcraft" />
              )}
            </div>

            {isTablet ? (
              <video
                playsInline
                autoPlay
                muted={true}
                loop
                className={`${styles.image} ${styles.one}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Wheatear/Untitled+2.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video
                playsinline
                autoPlay
                muted
                loop
                className={`${styles.image} ${styles.one}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Wheatear/Screen+Recording+2023-11-06+at+19.03.15.webm"
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </Link>
        </div>
        {isMobile && (
          <div className={`${styles.projectsTextBox} ${styles.left}`}>
            <div className={styles.projectsSubtitle}>Summer 2023</div>
            <div className={styles.projectsText}>
              Portfolio website for a small crafts business
            </div>
            <Link
              to="/projects"
              onClick={() => setSelectedProject(development[1])}
            >
              <div
                className={`${styles.projectsFindOutMore} ${styles.greenLink}`}
              >
                Find out more
              </div>
            </Link>
          </div>
        )}
      </div>
      <div className={`${styles.rowContainer} ${styles.two}`}>
        <div className={styles.imageContainer}>
          <Link to="/projects" onClick={() => setSelectedProject(projects[0])}>
            <div className={`${styles.projectsTitle} ${styles.odd}`}>
              {isMobile ? (
                <SpikeyStarRight
                  color="#FFFF00"
                  name1="Air Pollution"
                  name2="Exhibition Installation"
                />
              ) : (
                <SpikeyStarLeft
                  color="#FFFF00"
                  name1="Air Pollution"
                  name2="Exhibition Installation"
                />
              )}
            </div>
            {isTablet ? (
              <video
                playsInline
                autoPlay
                muted={true}
                loop
                className={`${styles.image} ${styles.two}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/exhibition.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video
                playsinline
                autoPlay
                muted
                loop
                className={`${styles.image} ${styles.two}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/AirPollution/exhibition.webm"
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </Link>
        </div>
        <div className={styles.projectsTextBox}>
          <div className={styles.projectsSubtitle}>Spring 2022</div>
          <div className={styles.projectsText}>
            Design and construction of an installation, highlighting issues
            surrounding air pollution in London. Exhibited at the Great
            Exhibition Road Festival.
          </div>
          <Link to="/projects" onClick={() => setSelectedProject(projects[0])}>
            <div
              className={`${styles.projectsFindOutMore} ${styles.yellowLink}`}
            >
              Find out more
            </div>
          </Link>
        </div>
      </div>
      <div className={` ${styles.rowContainer} ${styles.three}`}>
        {!isMobile && (
          <div className={`${styles.projectsTextBox} ${styles.left}`}>
            <div className={styles.projectsSubtitle}>Winter 2021/22</div>
            <div className={styles.projectsText}>
              Speculative design project of space rover control systems in 2040.
            </div>
            <Link
              to="/projects"
              onClick={() => setSelectedProject(projects[2])}
            >
              <div
                className={`${styles.projectsFindOutMore} ${styles.redLink}`}
              >
                Find out more
              </div>
            </Link>
          </div>
        )}
        <div className={styles.imageContainer}>
          <Link to="/projects" onClick={() => setSelectedProject(projects[2])}>
            <div className={`${styles.projectsTitle} ${styles.even}`}>
              {isMobile ? (
                <SpikeyStarLeft color="#FF0000" name="Reach" />
              ) : (
                <SpikeyStarRight color="#FF0000" name="Reach" />
              )}
            </div>

            {isTablet ? (
              <video
                playsInline
                autoPlay
                muted={true}
                loop
                className={`${styles.image} ${styles.three}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/Controller.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video
                playsinline
                autoPlay
                muted
                loop
                className={`${styles.image} ${styles.three}`}
              >
                <source
                  src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Reach/Controller.webm"
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </Link>
        </div>
        {isMobile && (
          <div className={`${styles.projectsTextBox} ${styles.left}`}>
            <div className={styles.projectsSubtitle}>Winter 2021/22</div>
            <div className={styles.projectsText}>
              Speculative design project of space rover control systems in 2040.
            </div>
            <Link
              to="/projects"
              onClick={() => setSelectedProject(projects[2])}
            >
              <div
                className={`${styles.projectsFindOutMore} ${styles.redLink}`}
              >
                Find out more
              </div>
            </Link>
          </div>
        )}
      </div>
      <div className={`${styles.rowContainer} ${styles.four}`}>
        <div className={styles.imageContainer}>
          <Link to="/projects" onClick={() => setSelectedProject(projects[3])}>
            <div className={`${styles.projectsTitle} ${styles.odd}`}>
              {isMobile ? (
                <SpikeyStarRight
                  color="#FF00FF"
                  name1="Spatial Audio"
                  name2="Experiments Turntable"
                />
              ) : (
                <SpikeyStarLeft
                  color="#FF00FF"
                  name1="Spatial Audio"
                  name2="Experiments Turntable"
                />
              )}
            </div>
            <img
              src={
                "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/Turntable/turntable.webp"
              }
              alt="Turntable in situ"
              className={`${styles.image} ${styles.four}`}
            />
          </Link>
        </div>

        <div className={`${styles.projectsTextBox} ${styles.right}`}>
          <div className={styles.projectsSubtitle}>Summer 2021</div>
          <div className={styles.projectsText}>
            Mechanical design of lab equipment used for audio-spatial
            experiments at the Audio Experience Laboratory, Imperial College
            London.
          </div>
          <Link to="/projects" onClick={() => setSelectedProject(projects[3])}>
            <div className={`${styles.projectsFindOutMore} ${styles.pinkLink}`}>
              Find out more
            </div>
          </Link>
        </div>
      </div>
      <div className={`${styles.rowContainer} ${styles.five}`}>
        {!isMobile && (
          <div className={`${styles.projectsTextBox} ${styles.left}`}>
            <div className={styles.projectsSubtitle}>Summer 2020</div>
            <div className={styles.projectsText}>
              Internship at Studio Drift.
            </div>
            <Link
              to="/projects"
              onClick={() => setSelectedProject(projects[4])}
            >
              <div
                className={`${styles.projectsFindOutMore} ${styles.blueLink}`}
              >
                Find out more
              </div>
            </Link>
          </div>
        )}
        <div className={styles.imageContainer}>
          <Link to="/projects" onClick={() => setSelectedProject(projects[4])}>
            <div className={`${styles.projectsTitle} ${styles.even}`}>
              {isMobile ? (
                <SpikeyStarLeft color="#0000FF" name="Studio Drift" />
              ) : (
                <SpikeyStarRight color="#0000FF" name="Studio Drift" />
              )}
            </div>
            <img
              src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/StudioDrift/exhibition1.jpg"
              alt="Studio drift exhibition"
              className={`${styles.image} ${styles.five}`}
            />
          </Link>
        </div>
        {isMobile && (
          <div className={`${styles.projectsTextBox} ${styles.left}`}>
            <div className={styles.projectsSubtitle}>Summer 2020</div>
            <div className={styles.projectsText}>
              Internship at Studio Drift.
            </div>
            <Link
              to="/projects"
              onClick={() => setSelectedProject(projects[3])}
            >
              <div
                className={`${styles.projectsFindOutMore} ${styles.blueLink}`}
              >
                Find out more
              </div>
            </Link>
          </div>
        )}
      </div>
      <div className={`${styles.rowContainer} ${styles.zero}`}>
        <div className={styles.imageContainer}>
          <Link to="/projects" onClick={() => setSelectedProject(projects[5])}>
            <div className={`${styles.projectsTitle} ${styles.odd}`}>
              {isMobile ? (
                <SpikeyStarRight color="#00FFFF" name="Consumer Electronics" />
              ) : (
                <SpikeyStarLeft color="#00FFFF" name="Consumer Electronics" />
              )}
            </div>
            <img
              src={
                "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/EDP/render-landscape.webp"
              }
              alt="Render of a gardening device"
              className={`${styles.image} ${styles.zero}`}
            />
          </Link>
        </div>

        <div className={`${styles.projectsTextBox} ${styles.right}`}>
          <div className={styles.projectsSubtitle}>Winter 2020</div>
          <div className={styles.projectsText}>
            A project designing a power tool for use by elderly people in order
            to help them take better care of their outdoor spaces. The product
            is designed to remove weeds from cracks in a patio or between paving
            slabs.
          </div>
          <Link to="/projects" onClick={() => setSelectedProject(projects[4])}>
            <div
              className={`${styles.projectsFindOutMore} ${styles.lightBlueLink}`}
            >
              Find out more
            </div>
          </Link>
        </div>
      </div>
      <Link to="/projects">
        <div className={styles.moreProjects}>See more projects</div>
      </Link>
    </div>
  );
}

export default Projects;
