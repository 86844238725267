import React, { useState, useEffect } from "react";
import styles from "./SolarPotential.module.css";

function SolarPotential() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isTablet = windowWidth <= 1024;

  return (
    <div className={styles.solarPotentialContentContainer}>
      Solar Potential is a side project where I have created an interactive web
      app that uses a custom image-recognition machine learning model. The aim
      of the project was to display information on the potential solar output
      from solar panels within an area - using satellite imagery.
      <br />
      <br />
      The intended impact of the project is to increase uptake of solar panel
      installation on private properties, by creating a platform that can be
      used by potential customers to quickly get an idea of the solar potential
      of their rooftops. It could also be used by solar panel installation
      companies for more efficient marketing.
      <br />
      <br />
      <div className={styles.inlineLink}>
        The latest iteration of the project can be viewed{" "}
        <a href={"/solar-potential"}>here</a>.
      </div>
      <br />
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.solarPotentialVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/tablet.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.solarPotentialVisual}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/tablet.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      {isTablet ? (
        <video
          playsInline
          autoPlay
          muted={true}
          loop
          className={styles.solarPotentialVisualPhone}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/phone.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <video
          playsinline
          autoPlay
          muted
          loop
          className={styles.solarPotentialVisualPhone}
        >
          <source
            src="https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/phone.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      )}
      Below I have broken this project down into the sub-tasks that made up the
      project and taught me about different concepts related to AI, satellite
      imagery and web app integration.
      <br />
      <br />
      <div className={styles.solarPotentialSubtitle}>
        Initial Research and Experimentation:
      </div>
      <br />
      After doing thorough research into geo-spatial information providers, I
      determined that there was not a reliable service that provided up-to-date
      and accurate building footprint and satellite imagery data. Because of
      this, the first step of this project was as follows. Create a semantic
      segmentation model, that for a given satellite image, will identify the
      areas of the image that are rooftops.
      <br />
      <br />
      To train the model I needed a dataset of random uk satellite images,
      paired with corresponding masks which identify where the buildings are.
      <br />
      <br />
      I was able to generate a large number of satellite images which included
      buildings, using a random postcode generator and the google maps static
      API. The main challenge was creating the mask dataset. Initially I created
      a dataset of 50 image / mask pairs by manually labelling the images using
      the image labelling service CVAT. With data augmentation I increased my
      sample size to 150 image mask pairs.
      <br />
      <br />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/cvat.webp"
        }
        alt="Manual labelling process"
        className={styles.solarPotentialVisual}
      />
      <br />
      <br />
      I determined that the best way to train a model would be to implement a
      deep learning model for semantic segmentation using the U-Net architecture
      with a ResNet34 backbone. I came to this conclusion after significant
      literature research.
      <br />
      <br />
      Training this type of model on my input data produced the following output
      prediction:
      <br />
      <br />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/bad-prediction.webp"
        }
        alt="Poor prediction of first model"
        className={styles.solarPotentialVisual}
      />
      <br />
      <br />
      It was a positive sign that flat features were being identified, but it
      was clear that the model was not advanced enough to pick out individual
      rooftops. Clearly a much larger dataset was required, and the current -
      time consuming - manual labelling of tiles was not going to be suitable
      for this task.
      <br />
      <br />
      <div className={styles.solarPotentialSubtitle}>
        Automating data collection:
      </div>
      <br />
      To gather a larger dataset I implemented the process outlined in the top
      half of the flowchart below. This involved getting random postcodes,
      convert them to lat/long coordinates, then using two different APIs for
      getting satellite imagery and building footprint data. The data from these
      APIs required processing so that an image and matching binary mask was
      created for each image. This was put into an automated pipeline so that
      over 1000 image / mask pairs could be created within a few minutes.
      <br />
      <br />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/flowchart.webp"
        }
        alt="Flow chart"
        className={styles.solarPotentialFlowchart}
      />
      <br />
      <br />
      An example of the training data is shown below, with a satellite image and
      it's corresponding mask.
      <br />
      <br />
      <br />
      <div className={styles.imagePair}>
        <img
          src={
            "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/AL7+2HL.webp"
          }
          alt="Flow chart"
          className={styles.imagePairImage}
        />
        <img
          src={
            "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/mask_AL7+2HL.webp"
          }
          alt="Flow chart"
          className={styles.imagePairImage}
        />
      </div>
      <br />
      <br />
      <div className={styles.solarPotentialSubtitle}>
        Creating a semantic-segmentation model:
      </div>
      <br />
      <br />
      With the dataset created I was able to create and run a script that
      employed a U-Net architecture—a powerful tool for semantic segmentation
      tasks. The model is designed with encoder and decoder blocks, effectively
      capturing contextual information and spatial details.
      <br />
      <br />
      The compiled model used the Adam optimizer with a learning rate of 1e-3
      and binary cross-entropy as the loss function. I trained the U-Net model
      on the curated dataset over 100 epochs.
      <br />
      <br />
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/loss-graph.webp"
        }
        alt="Poor prediction of first model"
        className={styles.solarPotentialVisual}
      />
      <br />
      <br />
      After training was completed. Average IoU accuracy was calculated to be
      0.695. The graphic below displays some predictions along side the source
      image and ground truth.
      <img
        src={
          "https://speechleyportfoliobucket.s3.eu-west-2.amazonaws.com/images/SolarPotential/predicitons.webp"
        }
        alt="Poor prediction of first model"
        className={styles.solarPotentialVisual}
      />
      I was happy with this model as an initial attempt, and implemented it as
      an online webapp. This was a big challenge, in particular hosting the
      model on an online server so that it can always be accessed. I chose to
      use the platform Heroku for this task.
      <br />
      <br />I learnt a huge amount doing this project and am excited for further
      iterations of the webapp, with more comprehensive features and a more
      accurate model.
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default SolarPotential;
