import React from "react";

const FourPoints = ({ number }) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2922 3.75713C40.2919 -1.8192 48.7638 0.450845 48.5735 6.77993L48.0623 23.7735C47.994 26.0455 49.2154 28.1611 51.2172 29.2379L66.1896 37.2921C71.766 40.2918 69.4959 48.7637 63.1668 48.5734L46.1732 48.0622C43.9012 47.9939 41.7857 49.2153 40.7088 51.2171L32.6547 66.1895C29.655 71.7659 21.183 69.4958 21.3734 63.1667L21.8845 46.1731C21.9529 43.9011 20.7315 41.7856 18.7297 40.7088L3.75722 32.6546C-1.81911 29.6549 0.450935 21.183 6.78002 21.3733L23.7736 21.8844C26.0456 21.9528 28.1612 20.7314 29.238 18.7296L37.2922 3.75713Z"
        fill="#009EE1"
      />

      <text
        x="53%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20"
        fontWeight="600"
        fill="white"
      >
        {number}
      </text>
    </svg>
  );
};

export default FourPoints;
