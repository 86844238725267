import AirPollutionInstallation from "./Content/AirPollutionInstallation/AirPollutionInstallation.view";
import Turntable from "./Content/Turntable/Turntable.view";
import StudioDrift from "./Content/StudioDrift/StudioDrift.view";
import Reach from "./Content/Reach/Reach.view";
import EDP from "./Content/EDP/EDP.view";
import Procreate from "./Content/Procreate/Procreate.view";
import Renders from "./Content/Renders/Renders.view";
import GravitySketch from "./Content/GravitySketch/GravitySketch.view";
import Sketches from "./Content/Sketches/Sketches.view";
import IOT from "./Content/IOT/IOT.view";
import Wheatear from "./Content/Wheatear/Wheatear.view";
import SolarPotential from "./Content/SolarPotential/SolarPotential.view";

const development = [
  {
    list: "solar_potential",
    title: "Solar Potential",
    key: "solar_potential",
    year: "2024",
    theme_color: "lightBlue",
    color_code: "#00FFFF",
    content: <SolarPotential />,
  },
  {
    list: "wheatear",
    title: "Wheatear Strawcraft",
    key: "wheatear",
    year: "2023",

    theme_color: "green",
    color_code: "#00FF00",
    content: <Wheatear />,
  },
];

const projects = [
  {
    list: "des_eng",
    title: "Air Pollution Installation",
    key: "air_pollution_installation",
    year: "2022",
    theme_color: "lightBlue",
    color_code: "#00FFFF",
    content: <AirPollutionInstallation />,
  },
  {
    list: "iot_device",
    title: "IOT smart bed",
    key: "iot_device",
    year: "2022",
    theme_color: "green",
    color_code: "#00FF00",
    content: <IOT />,
  },
  {
    list: "des_eng",
    title: "Reach",
    key: "reach",
    year: "2021",
    theme_color: "yellow",
    color_code: "#FFFF00",
    content: <Reach />,
  },
  {
    list: "des_eng",
    title: "Spatial Audio Experiments Turntable",
    key: "turntable",
    year: "2021",
    theme_color: "red",
    color_code: "#FF0000",
    content: <Turntable />,
  },
  {
    list: "des_eng",
    title: "Studio Drift Internship",
    key: "studio_drift",
    year: "2020",
    theme_color: "pink",
    color_code: "#FF00FF",
    content: <StudioDrift />,
  },
  {
    list: "edp",
    title: "Consumer Electronics Design Project",
    key: "edp",
    year: "2020",
    theme_color: "blue",
    color_code: "#0000FF",
    content: <EDP />,
  },
];

const sketches = [
  {
    list: "procreate",
    title: "Procreate",
    key: "procreate",
    year: "2020 - Present",
    theme_color: "lightBlue",
    color_code: "#00FFFF",
    content: <Procreate />,
  },
  {
    list: "des_eng",
    title: "Renders",
    key: "renders",
    year: "2019 - 2021",
    theme_color: "green",
    color_code: "#00FF00",
    content: <Renders />,
  },
  {
    list: "gravity_sketch",
    title: "Gravity Sketch",
    key: "gravitysketch",
    year: "2021",
    theme_color: "yellow",
    color_code: "#FFFF00",
    content: <GravitySketch />,
  },
  {
    list: "sketches",
    title: "Sketches",
    key: "sketches",
    year: "2016 - Present",
    theme_color: "red",
    color_code: "#FF0000",
    content: <Sketches />,
  },
];

export { projects, sketches, development };
